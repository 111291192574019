@font-face {
  font-family: "Cerebri Sans";
  src: url("./_fonts/CerebriSans-Regular.woff2") format("woff2"),
    url("./_fonts/CerebriSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./_fonts/CerebriSans-Medium.woff2") format("woff2"),
    url("./_fonts/CerebriSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./_fonts/CerebriSans-SemiBold.woff2") format("woff2"),
    url("./_fonts/CerebriSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cerebri Sans";
  src: url("./_fonts/CerebriSans-Bold.woff2") format("woff2"),
    url("./_fonts/CerebriSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lyon Text";
  src: url("./_fonts/LyonText-Regular.woff2") format("woff2"),
    url("./_fonts/LyonText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lyon Text";
  src: url("./_fonts/LyonText-SemiboldItalic.woff2") format("woff2"),
    url("./_fonts/LyonText-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Cerebri Sans";
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
  -webkit-font-smoothing: antialiased;
}

a {
  opacity: 1;
  transition: right 1.5s cubic-bezier(0.6, 0, 0.2, 1) 0s, opacity 0.2s ease 0s;
}

a:hover {
  opacity: 0.4;
  transition: right 1.5s cubic-bezier(0.6, 0, 0.2, 1) 0s, opacity 0.2s ease 0s;
}
